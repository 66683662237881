.ql-container.ql-snow {
  border: none !important;
  font-size: 18px;
}

.ql-container {
  font-family: "Open Sans", sans-serif !important;
}

.ql-editor {
  padding: 5px !important;
}


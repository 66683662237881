@-webkit-keyframes autofill {
  to {
      color: black;
      background: transparent;
  }
}

@keyframes autofill {
  to {
      color: black;
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-name: autofill;
  animation-fill-mode: both;
}